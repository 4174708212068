import React from "react"
import { graphql } from "gatsby"
//import PropTypes from "prop-types"
//import PostIcons from "../components/post-icons"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import '../sass/main.scss';

//import { rhythm } from "../utils/typography"

const PostTemplate = ({ data }) => {
  console.log(data)
  const post = data.wordpressPost
  return(
    <Layout>
      <SEO title={post.yoast.title} description={post.yoast.metadesc} />
      <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
        {
          post.featured_media && post.featured_media.localFile &&
            <Img
              src={post.featured_media.localFile.childImageSharp.fluid.src}
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              alt={post.featured_media.title}
            />
        }

        <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      slug
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      yoast {
        title
        metadesc
        metakeywords
      }
    }
  }
`
